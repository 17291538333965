
document.addEventListener('DOMContentLoaded', () => {
    const searchInput = document.getElementById('faq-search');
    const faqItems = document.querySelectorAll('.faq-item');
    const alwaysVisibleItems = document.querySelectorAll('.faq-item.always-visible');

    if (!searchInput || faqItems.length === 0) {
        console.error("Das Suchfeld oder die FAQ-Elemente konnten nicht gefunden werden.");
        return;
    }

    /**
     * Funktion: Alle Fragen ausblenden (nur Hintergrundfragen)
     */
    const hideBackgroundQuestions = () => {
        faqItems.forEach(item => {
            if (!item.classList.contains('always-visible')) {
                item.classList.remove('visible');  // Hintergrundfragen verbergen
            }
        });
    };

    /**
     * Funktion: Nur passende Fragen anzeigen
     */
    const showMatchingQuestions = (searchValue) => {
        let hasMatches = false;

        faqItems.forEach(item => {
            const question = item.getAttribute('data-question').toLowerCase();
            if (question.includes(searchValue)) {
                item.classList.add('visible');  // Passende Fragen anzeigen
                hasMatches = true;
            } else if (!item.classList.contains('always-visible')) {
                item.classList.remove('visible');  // Nur Hintergrundfragen ausblenden
            }
        });

        return hasMatches;
    };

    /**
     * Eventlistener für die Suche
     */
    searchInput.addEventListener('input', (e) => {
        const searchValue = e.target.value.toLowerCase().trim();

        if (!searchValue) {
            // Suchfeld leer -> Nur die häufigsten Fragen anzeigen
            hideBackgroundQuestions();  // Hintergrundfragen ausblenden
            alwaysVisibleItems.forEach(item => {
                item.classList.add('visible');  // Häufigste Fragen einblenden
            });
        } else {
            hideBackgroundQuestions();  // Alle Hintergrundfragen ausblenden
            const hasMatches = showMatchingQuestions(searchValue);

            if (!hasMatches) {
                // Keine Treffer gefunden -> Häufigste Fragen ausblenden
                alwaysVisibleItems.forEach(item => {
                    item.classList.remove('visible');
                });
            }
        }
    });

    /**
     * FAQ-Klick-Logik: Antworten ein- und ausklappen
     */
    faqItems.forEach(item => {
        const question = item.querySelector('.faq-question');
        const answer = item.querySelector('.faq-answer');
        const icon = item.querySelector('.faq-icon');

        if (!question || !answer || !icon) {
            console.warn("Ein FAQ-Element hat fehlende Teile.");
            return;
        }

        question.addEventListener('click', () => {
            const isCurrentlyOpen = answer.style.display === 'block';

            // Alle Antworten schließen
            faqItems.forEach(i => {
                i.querySelector('.faq-answer').style.display = 'none';
                i.querySelector('.faq-icon').classList.remove('rotate');
            });

            // Wenn sie nicht geöffnet war, öffnen
            if (!isCurrentlyOpen) {
                answer.style.display = 'block';
                icon.classList.add('rotate');
            }
        });
    });
});
document.addEventListener("DOMContentLoaded", () => {
    // Toggle the infotext visibility for multiple info boxes
    window.toggleInfo = (infoId) => {
        const infoBox = document.getElementById(infoId);
        infoBox.style.display = infoBox.style.display === "none" ? "block" : "none";
    };
});



